<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        <b>Administracja nagrodami</b> w tym możesz dodawać i archiwizować
        nagrody.
      </div>
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <KTCodePreview
          title="Lista kategorii nagród"
          v-if="categoryState === 'loaded'"
        >
          <template v-slot:toolbar-action>
            <div>
              <b-button
                variant="primary"
                size="sm"
                class="mb-1"
                @click="newCategory()"
              >
                Dodaj kategorie<b-icon icon="plus" aria-hidden="true"></b-icon>
              </b-button>
            </div>
          </template>
          <template v-slot:preview>
            <b-table
              responsive
              striped
              hover
              primary-key="id"
              :items="categories.items"
              :fields="categories.fields"
              selectable
              select-mode="single"
              @row-selected="onCategorySelected"
            >
              <template #cell(actions)="row">
                <span v-if="row.item.id !== -1">
                  <b-button
                    variant="primary"
                    size="sm"
                    class="mb-1"
                    @click="editCategory(row.item, false)"
                  >
                    <b-icon icon="pencil" aria-hidden="true"></b-icon>
                  </b-button>
                  &nbsp;
                  <b-button
                    variant="danger"
                    size="sm"
                    class="mb-1"
                    @click="deleteCategory(row)"
                  >
                    <b-icon icon="trash" aria-hidden="true"></b-icon>
                  </b-button>
                </span>
                <div v-else style="height: 33px"></div>
              </template>
            </b-table>
          </template>
        </KTCodePreview>
        <div class="d-flex justify-content-center mb-3" v-else>
          <b-spinner label="Ładowanie..."></b-spinner>
        </div>
      </div>
      <div class="col-md-12">
        <KTCodePreview title="Lista nagród" v-if="priseState === 'loaded'">
          <template v-slot:preview>
            <div>
              <b-row>
                <b-col></b-col>
                <b-col lg="6" class="my-1">
                  <b-form-group
                    label="Filtuj"
                    label-for="filter-input"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    class="mb-0"
                  >
                    <b-input-group size="sm">
                      <b-form-input
                        id="filter-input"
                        v-model="filter"
                        type="search"
                        placeholder="szukaj..."
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''"
                          >Wyczyść</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-table
                responsive
                striped
                hover
                :items="prises.items"
                :fields="prises.fields"
                :filter="filter"
              >
                <template #cell(actions)="row">
                  <b-button
                    variant="primary"
                    size="sm"
                    class="mb-1"
                    @click="editPrise(row.item)"
                  >
                    <b-icon icon="pencil" aria-hidden="true"></b-icon>
                  </b-button>
                </template>
              </b-table>
            </div>
          </template>
        </KTCodePreview>
      </div>
    </div>
    <template>
      <b-modal
        id="categoryDetail"
        size="lg"
        scrollable
        ref="categoryDetail"
        title="Edytor kategorii"
      >
        <b-container>
          <b-form novalidate :validated="categoryFormValidated">
            <b-form-group
              id="fieldset-1"
              label="Nazwa kategorii"
              label-for="name"
            >
              <b-row>
                <b-col cols="12">
                  <b-input v-model="category.name" required name="name" />
                  <div class="invalid-feedback">
                    Proszę wpisać nazwę kategorii.
                  </div>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group
              id="fieldset-1"
              label="Opis kategorii"
              label-for="description"
            >
              <b-row>
                <b-col cols="12">
                  <b-form-textarea
                    name="description"
                    v-model="category.description"
                    rows="3"
                    max-rows="6"
                    required
                  ></b-form-textarea>
                  <div class="invalid-feedback">
                    Proszę wpisać opis.
                  </div>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group label="Wgraj zdjęcie kategorii" label-for="image">
              <b-row>
                <b-col cols="12">
                  <b-form-file
                    accept="image/*"
                    name="image"
                    @change="onCategoryFileChange"
                  ></b-form-file>
                </b-col>
              </b-row>
            </b-form-group>
          </b-form>
          <div class="row">
            <div class="col-md-12">
              <div class="preview">
                <b-img
                  fluid
                  thumbnail
                  v-if="getCategoryImage()"
                  :src="getCategoryImage()"
                />
              </div>
            </div>
          </div>
        </b-container>
        <template #modal-footer="{ ok, hide }">
          <b-button
            variant="danger"
            size="sm"
            class="float-right"
            @click="saveCategory()"
          >
            Zapisz
          </b-button>
          <b-button
            variant="outline-secondary"
            size="sm"
            class="float-right"
            @click="cancelCategoryEdit()"
          >
            Anuluj
          </b-button>
        </template>
      </b-modal>
    </template>
    <template>
      <b-modal
        id="priseDetail"
        size="lg"
        scrollable
        ref="priseDetail"
        title="Edytor nagrody"
      >
        <b-container>
          <b-form novalidate :validated="priseFormValidated">
            <b-form-group
              id="fieldset-1"
              label="Nazwa nagrody"
              label-for="name"
            >
              <b-row>
                <b-col cols="12">
                  <b-input v-model="prise.erpPrize.name" readonly name="name" />
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group id="fieldset-1" label="Kod nagrody" label-for="code">
              <b-row>
                <b-col cols="12">
                  <b-input v-model="prise.erpPrize.code" readonly name="code" />
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group
              id="fieldset-1"
              label="Koszt nagrody (punkty)"
              label-for="cost"
            >
              <b-row>
                <b-col cols="12">
                  <b-input v-model="prise.erpPrize.cost" readonly name="cost" />
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group label="Kategoria">
              <b-form-radio
                v-for="cat in categories.items"
                v-model="prise.categoryId"
                name="some-radios"
                :value="cat.id"
                :key="cat.id"
                >{{ cat.name }}</b-form-radio
              >
            </b-form-group>
            <b-form-group
              id="fieldset-1"
              label="Opis nagrody"
              label-for="description"
            >
              <b-row>
                <b-col cols="12">
                  <b-form-textarea
                    name="description"
                    v-model="prise.description"
                    rows="3"
                    max-rows="6"
                    required
                  ></b-form-textarea>
                  <div class="invalid-feedback">
                    Proszę wpisać opis.
                  </div>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group label="Wgraj zdjęcia nagrody" label-for="image">
              <b-row>
                <b-col cols="12">
                  <b-form-file
                    accept="image/*"
                    name="image"
                    @change="onPrizeFilesChange"
                    multiple
                  ></b-form-file>
                </b-col>
              </b-row>
            </b-form-group>
          </b-form>
          <div class="row">
            <b-card
              v-for="(image, index) in getPrizeImages"
              :key="index"
              :img-src="image.img"
              img-top
              class="mb-2 col-md-6"
            >
              <b-button
                @click="prizeImageToDelete(image.index, image.source)"
                variant="warning"
                >Usuń</b-button
              >
            </b-card>
          </div>
        </b-container>
        <template #modal-footer="{ ok, hide }">
          <b-button
            variant="danger"
            size="sm"
            class="float-right"
            @click="savePrise()"
          >
            Zapisz
          </b-button>
          <b-button
            variant="outline-secondary"
            size="sm"
            class="float-right"
            @click="cancelPriseEdit()"
          >
            Anuluj
          </b-button>
        </template>
      </b-modal>
    </template>
  </div>
</template>
<style>
.action-column-multi {
  width: 100px;
}
.action-column-single {
  width: 50px;
}
.preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview img {
  max-width: 100%;
  max-height: 200px;
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTCodePreview from "@/view/content/CodePreview";
import ApiService from "@/core/services/api.service";
import Commons from "@/core/services/commons.service";

export default {
  name: "PrizesAdmin",
  data() {
    return {
      categoryState: "loading",
      categories: {
        fields: [
          {
            key: "name",
            label: "Nazwa"
          },
          {
            key: "description",
            label: "Opis"
          },
          {
            key: "actions",
            label: "Akcje",
            thClass: "action-column-multi"
          }
        ],
        items: []
      },
      selectedCategory: null,
      priseState: "loading",
      prises: {
        fields: [
          {
            key: "erpPrize.name",
            label: "Nazwa"
          },
          {
            key: "erpPrize.code",
            label: "Kod"
          },
          {
            key: "erpPrize.cost",
            label: "Punkty"
          },
          {
            key: "description",
            label: "Opis"
          },
          {
            key: "actions",
            label: "Akcje",
            thClass: "action-column-single"
          }
        ],
        items: []
      },
      category: {},
      categoryUrl: null,
      categoryImageFile: null,
      priseImageUrls: [],
      priseImageFiles: [],
      categoryBackup: {},
      isNewCategory: false,
      categoryFormValidated: false,
      prizeImagesToDelete: [],
      prise: {
        erpPrize: {}
      },
      priseBackup: {},
      priseFormValidated: false,
      filter: null
    };
  },
  components: {
    KTCodePreview
  },
  computed: {
    getPrizeImages() {
      return [
        ...(this.priseImageUrls || []).map((img, index) => {
          return { img: img, index: index, source: "NEW" };
        }),
        ...(this.prise.images || []).map((id, index) => {
          return { img: this.getImageUrl(id), index: index, source: "OLD" };
        })
      ];
    }
  },
  methods: {
    prizeImageToDelete(index, source) {
      if (source === "NEW") {
        this.priseImageUrls.splice(index, 1);
      }
      if (source === "OLD") {
        let deleted = this.prise.images.splice(index, 1);
        this.prizeImagesToDelete.push(deleted[0]);
      }
    },
    getCategoryImage() {
      if (this.categoryUrl) {
        return this.categoryUrl;
      } else if (this.category.image) {
        return this.getImageUrl(this.category.image);
      }
      return null;
    },
    getImageUrl: Commons.getImageUrl,
    onPrizeFilesChange(e) {
      this.priseImageFiles = [];
      for (let i = 0; i < e.target.files.length; i++) {
        this.priseImageFiles.push(e.target.files[i]);
      }
      this.priseImageUrls = this.priseImageFiles.map(file =>
        URL.createObjectURL(file)
      );
    },
    onCategoryFileChange(e) {
      this.categoryImageFile = e.target.files[0];
      this.categoryUrl = URL.createObjectURL(this.categoryImageFile);
    },
    onCategorySelected(row) {
      this.selectedCategory = row.length ? row[0] : null;
      this.fetchPrises();
    },
    deleteCategory(row) {
      ApiService.delete(`/category/${row.item.id}`).then(() => {
        this.categories.items.splice(row.index, 1);
      });
    },
    newCategory() {
      this.editCategory({}, true);
    },
    editCategory(row, isNew) {
      this.isNewCategory = isNew;
      this.categoryFormValidated = false;
      this.categoryBackup = Object.assign({}, row);
      this.category = row;
      this.$refs.categoryDetail.show("categoryDetail");
    },
    cancelCategoryEdit() {
      this.category = Object.assign(this.category, this.categoryBackup);
      this.$refs.categoryDetail.hide("categoryDetail");
    },
    saveCategory() {
      if (
        this.category.name &&
        this.category.name.trim().length > 0 &&
        this.category.description &&
        this.category.description.trim().length > 0
      ) {
        const save = () => {
          ApiService.post("/category", this.category)
            .then(response => {
              this.$refs.categoryDetail.hide("categoryDetail");
              if (this.isNewCategory) {
                this.categories.items.push(response.data);
              }
            })
            .catch(() => {
              this.categoryFormValidated = true;
            });
        };
        if (this.categoryImageFile) {
          this.deleteImage(this.category.image).then(() =>
            this.saveImage(this.categoryImageFile).then(response => {
              this.categoryUrl = null;
              this.categoryImageFile = null;
              this.category.image = response.data;
              save();
            })
          );
        } else {
          save();
        }
      } else {
        this.categoryFormValidated = true;
      }
    },
    deleteImage(id) {
      if (id) {
        return ApiService.delete(`/image/${id}`);
      } else {
        return Promise.resolve();
      }
    },
    saveImage(file) {
      const formData = new FormData();
      formData.append("files", file);
      return ApiService.post("/image", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
    },
    fetchPrises() {
      if (!this.selectedCategory) {
        this.priseState = "loading";
        return;
      }
      ApiService.get("/prizes/" + this.selectedCategory.id).then(response => {
        this.prises.items = response.data;
        this.priseState = "loaded";
      });
    },
    editPrise(row) {
      this.categoryFormValidated = false;
      this.priseBackup = Object.assign({}, row);
      this.prise = row;
      this.$refs.priseDetail.show("priseDetail");
    },
    savePrise() {
      const save = () =>
        ApiService.post("/prize", this.prise)
          .then(() => {
            this.$refs.priseDetail.hide("priseDetail");
            this.fetchPrises();
          })
          .catch(() => {
            this.priseFormValidated = true;
          });
      if (this.prise.description && this.prise.description.trim().length > 0) {
        if (this.priseImageFiles && this.priseImageFiles.length) {
          Promise.all([
            ...this.prizeImagesToDelete.map(imgId => this.deleteImage(imgId)),
            ...this.priseImageFiles.map(file => this.saveImage(file))
          ]).then(responses => {
            this.prise.images.push(
              ...responses.filter(d => d).map(response => response.data)
            );
            save();
          });
        } else {
          Promise.all(
            this.prizeImagesToDelete.map(imgId => this.deleteImage(imgId))
          ).then(() => {
            save();
          });
        }
        this.priseImageFiles = [];
        this.priseImageUrls = [];
      } else {
        this.priseFormValidated = true;
      }
    },
    cancelPriseEdit() {
      this.prise = Object.assign(this.prise, this.priseBackup);
      this.$refs.priseDetail.hide("priseDetail");
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Administrator" },
      { title: "Katalog Nagród" },
      { title: "" }
    ]);
    ApiService.get("/prize/categories").then(response => {
      this.categories.items = [
        {
          id: -1,
          name: "Nieprzypisane",
          description: "Nagrody dostępne w ERP (niedostępne w Agropula)"
        },
        ...response.data
      ];
      this.categoryState = "loaded";
    });
  }
};
</script>
